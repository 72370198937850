<template>
  <q-btn
    v-if="inquiry"
    icon="sym_r_local_offer"
    color="neutral-7"
    flat
    dense
    :disable="isDisabled"
    @click="openCreateQuotationDialog"
  >
    <q-tooltip> {{ $t("createQuotationDialog.button") }}</q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { CAN_FINALIZE_BASE_INQUIRY_STATUSES } from "@/types/inquiry";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed } from "vue";
import CreateQuotationDialog from "./CreateQuotationDialog.vue";

const { inquiry } = storeToRefs(useCurrentInquiryStore());

const isDisabled = computed(() => {
  return (
    !inquiry.value ||
    !CAN_FINALIZE_BASE_INQUIRY_STATUSES.includes(
      inquiry.value.status.originalStatus
    )
  );
});

const q = useQuasar();

function openCreateQuotationDialog() {
  if (!inquiry.value) return;

  q.dialog({
    component: CreateQuotationDialog,
    componentProps: {
      inquiry: inquiry.value,
    },
  });
}
</script>
