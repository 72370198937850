import { client } from "@/api/client/client";

import type { Quotation } from "@/types/quotation";

export async function getQuotation(
  inboxId: number,
  inquiryId: number
): Promise<Quotation> {
  const response = await client.get(`/inquiries/${inquiryId}/quotation/`);
  return response.data;
}

export async function updateQuotation(
  inquiryId: number,
  quotation: Partial<Quotation>
): Promise<Quotation> {
  const response = await client.patch(
    `/inquiries/${inquiryId}/quotation/`,
    quotation
  );
  return response.data;
}

export async function finalizeQuotation(inquiryId: number): Promise<void> {
  await client.post(`/inquiries/${inquiryId}/quotation/finalize/`);
}

export async function getQuotationFile(inquiryId: number): Promise<File> {
  const response = await client.get(
    `/inquiries/${inquiryId}/quotation/download/`,
    { responseType: "blob" }
  );
  const blob = new Blob([response.data], {
    type: response.headers["contentType"],
  });
  return new File([blob], response.headers["filename"], {
    type: response.headers["contentType"] as string,
  });
}

export async function downloadQuotation(inquiryId: number): Promise<void> {
  window.open(`/api/inquiries/${inquiryId}/quotation/download/`, "_blank");
}
