<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" persistent maximized>
    <q-card class="q-dialog-plugin column no-wrap header" @keydown.stop>
      <div
        class="row no-wrap items-center justify-between header-row q-px-md q-py-sm"
      >
        <div class="col-auto text-h6">
          {{ $t("createQuotationDialog.title") }}
        </div>
        <q-btn flat icon="close" @click="onDialogHide" aria-label="Close" />
      </div>
      <div class="flex-1 overflow-hidden full-height">
        <div class="row no-wrap full-height">
          <!-- Left side: PDF Viewer -->
          <div class="col relative-position full-height scroll">
            <pdf-viewer
              v-if="pdfFile"
              :file="pdfFile"
              class="full-height"
              initial-scale="full-page"
            />
            <div v-else class="column items-center justify-center full-height">
              <q-icon name="insert_drive_file" size="5rem" color="grey-5" />
              <div class="text-h6 q-mt-md">{{ $t("No PDF to display") }}</div>
            </div>
            <q-inner-loading :showing="isLoading || isFinalizing" />
          </div>

          <!-- Right side: Angebotsdetails -->
          <div class="right-side full-height column no-wrap scroll">
            <div class="q-pa-md col">
              <div class="text-h6 q-mb-md">
                {{ $t("createQuotationDialog.details") }}
              </div>
              <q-form class="column no-wrap items-start gap-md full-width">
                <q-input
                  dense
                  outlined
                  v-model="recipient"
                  :label="$t('createQuotationDialog.recipient')"
                  type="textarea"
                  autogrow
                  class="full-width"
                  :disable="isFinalizing"
                  @change="updateQuotation"
                />
                <q-input
                  dense
                  outlined
                  v-model="salutation"
                  :label="$t('createQuotationDialog.salutation')"
                  type="textarea"
                  autogrow
                  class="full-width"
                  :disable="isFinalizing"
                  @change="updateQuotation"
                />
                <q-input
                  dense
                  outlined
                  v-model="closing"
                  :label="$t('createQuotationDialog.closing')"
                  type="textarea"
                  autogrow
                  class="full-width"
                  :disable="isFinalizing"
                  @change="updateQuotation"
                />
                <q-input
                  dense
                  outlined
                  :label="$t('createQuotationDialog.discount')"
                  suffix="%"
                  v-model="discountRate"
                  type="number"
                  step="0.01"
                  input-style="text-align: right"
                  placeholder="0.00"
                  :disable="isFinalizing"
                  @change="updateQuotation"
                />
              </q-form>
            </div>
            <q-separator />
            <div
              class="column no-wrap items-start gap-md full-width col-auto q-pa-md"
            >
              <div class="row items-center gap-sm full-width">
                <q-btn
                  class="col"
                  :label="$t('createQuotationDialog.createFinal')"
                  color="primary"
                  :disable="isFinalizing || isLoading"
                  @click="finalizeQuotation"
                />
                <q-icon name="sym_r_help" size="1.2rem" color="neutral-7">
                  <q-tooltip
                    anchor="top right"
                    self="bottom right"
                    :offset="[0, 8]"
                  >
                    {{ $t("createQuotationDialog.createFinalTooltip") }}
                  </q-tooltip>
                </q-icon>
              </div>

              <div class="row items-center gap-sm full-width">
                <q-btn
                  class="col"
                  color="neutral-5"
                  :label="$t('createQuotationDialog.createDraft')"
                  :disable="isFinalizing || isLoading"
                  @click="downloadDraftQuotation"
                />
                <q-icon name="sym_r_help" size="1.2rem" color="neutral-7">
                  <q-tooltip
                    anchor="top right"
                    self="bottom right"
                    :offset="[0, 8]"
                  >
                    {{ $t("createQuotationDialog.createDraftTooltip") }}
                  </q-tooltip>
                </q-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import * as api from "@/api/quotation";
import PdfViewer from "@/components/PdfViewer/PdfViewer.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useDialogPluginComponent } from "quasar";
import { onMounted, ref } from "vue";

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide } = useDialogPluginComponent();

const { inboxId, inquiryId } = useRouteParams();

const pdfFile = ref<File | null>(null);
const recipient = ref("");
const salutation = ref("");
const closing = ref("");
const discountRate = ref<number | null>(null);
const isLoading = ref(false);
const isFinalizing = ref(false);

function roundDiscountRate() {
  if (discountRate.value) {
    discountRate.value = Math.round(discountRate.value * 100) / 100;
  }
}

async function updateQuotation() {
  roundDiscountRate();
  await api.updateQuotation(inquiryId.value, {
    recipient: recipient.value,
    salutation: salutation.value,
    closing: closing.value,
    discountRate: discountRate.value,
  });
  await updateDraftPDF();
}

async function updateDraftPDF() {
  isLoading.value = true;
  try {
    const pdfBlob = await api.getQuotationFile(inquiryId.value);
    pdfFile.value = new File([pdfBlob], "Angebotsentwurf.pdf", {
      type: "application/pdf",
    });
  } finally {
    isLoading.value = false;
  }
}

async function finalizeQuotation() {
  isFinalizing.value = true;
  try {
    await api.finalizeQuotation(inquiryId.value);
    await api.downloadQuotation(inquiryId.value);
  } finally {
    isFinalizing.value = false;
  }
  onDialogHide();
}

async function downloadDraftQuotation() {
  await api.downloadQuotation(inquiryId.value);
}

onMounted(async () => {
  const quotation = await api.getQuotation(inboxId.value, inquiryId.value);
  recipient.value = quotation.recipient || "";
  salutation.value = quotation.salutation || "";
  closing.value = quotation.closing || "";
  discountRate.value = quotation.discountRate || null;
  await updateDraftPDF();
});
</script>

<style scoped lang="scss">
.header-row {
  border-bottom: 1px solid $neutral-3;
}

.right-side {
  width: 600px;
  overflow: auto;
}

.scroll {
  overflow-y: auto;
}
</style>
